import React from 'react';

// H1 Component
const H1 = ({ children }) => <h1 className="text-4xl font-bold">{children}</h1>;

// H2 Component
const H2 = ({ children }) => <h2 className="text-3xl font-semibold mt-6">{children}</h2>;

// H3 Component
const H3 = ({ children }) => <h3 className="text-2xl font-medium mt-4">{children}</h3>;

export { H1, H2, H3 };
